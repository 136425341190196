import React from 'react';
import './seerecipe.css';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import IngredientsList from './IngredientsList';
import NutritionalValues from './NutritionalValues';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useData } from '../../DataProvider';
import { generateReport } from '../../utils/utils';
import CircularProgress from '@mui/material/CircularProgress';

const getColor = (value) => {
  if (value < 500) return "#38A48E";
  if (value < 1000) return "#FFD700";
  if (value < 1500) return "#FFA500";
  if (value < 3000) return "#FF6347";
  return "#FF6347";
};
const textStyle = {
  textAlign: "center",
  margin: 0,
  padding: 0,
  lineHeight: "1", // Pour réduire l'espacement vertical
};
const SeeRecipe = ({ p_recipe }) => {
  let loc = useLocation()
  if (loc.state) {
    console.log("mode page")
    const className = "seerecipes";
    const newStyles = `.${className} {
      margin-left: 17%;
      margin-right: 2%;
      }`;
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = newStyles;
    document.head.appendChild(styleSheet);
  }
  let recipe = loc.state ? loc.state.recipe : p_recipe
  console.log("loc state", loc.state)

  const { data: recettesDATA, prod_stats, reports, restaurant, listRestaurants, client, isLoading, error, accessToken } = useData();
  const [loadingExport, setLoadingExport] = React.useState(false);
  let allergens = ""
  recipe["Allergens"].forEach(allergen => {
    allergens += allergen.toUpperCase() + ", "
  });
  allergens = allergens.slice(0, -2)
  if (allergens === "") {
    allergens = "Aucun"
  }
  const recipe_footprint = (recipe.Footprint / recipe.Nb_portion).toFixed(0);

  const handleExport = (event, index) => {
    //TODO: Export the recipe to excel
    setLoadingExport(true)
    generateReport(recipe.id, restaurant, accessToken).then(() => {
      setLoadingExport(false)
    })
  }

  return (
    <div className="seerecipes">
      <div style={{ alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ alignItems: 'center', justifyContent: 'center', display: "flex", flexDirection: "row" }}>
          <img src="/logo_bis.svg" alt="logo_bis" />
          <h1 style={{ marginLeft: 10 }}>{recipe.Name}</h1>
          <Button disabled={loadingExport} onClick={(event) => handleExport(event, recipe.id)} variant="contained" style={{ backgroundColor: '#38A48E', color: '#FFFFFF', borderRadius: 10, marginLeft: 10 }}>
            Exporter l'analyse
            {loadingExport && (
              <>
                <CircularProgress />
              </>
            )}
          </Button>
        </div>
        <div style={{ alignItems: 'center', justifyContent: 'center', display: "flex", flexDirection: "row" }}>
          <h2 style={{ marginLeft: 10, marginTop: -10 }}>Pour {recipe.Nb_portion} personnes</h2>
        </div>
      </div>

      <Grid container spacing={2}> {/* Conteneur principal pour aligner les éléments côte à côte */}
        <Grid item xs={6}> {/* Première div à gauche */}
          <Grid container direction="column" spacing={2}> {/* Conteneur pour organiser les éléments en colonne */}
            <Grid item>
              <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', flexDirection: "row" }}>
                <img src="/logo_bis.svg" alt="logo_bis" />
                <h1 style={{ marginLeft: 10 }}>Indicateurs de la recette</h1>
              </div>
            </Grid>
            <Grid item>
              <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', flexDirection: "row", marginTop: -25 }}>
                <img src={`/nutriscore/${recipe.Nutriscore}.svg`}
                  alt={recipe.Nutriscore}
                  height="12%"
                  width="12%" />
                <img src={`/ecoscore/${recipe.Ecoscore}.svg`}
                  alt={recipe.Ecoscore}
                  height="5%"
                  width="5%"
                  style={{ marginLeft: 10 }} />
                <div style={{ display: "inline-block", textAlign: "center", marginLeft: 10 }}>
                  <h2 style={{ ...textStyle, color: getColor(recipe_footprint) }}>{recipe_footprint}</h2>
                  <h4 style={textStyle}>gCO₂e/portion</h4>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}> {/* Deuxième div à droite */}
          <Grid container direction="column" spacing={2}> {/* Conteneur pour organiser les éléments en colonne */}
            <Grid item>
              <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', flexDirection: "row" }}>
                <img src="/logo_bis.svg" alt="logo_bis" />
                <h1 style={{ marginLeft: 10 }}>Allergène(s) de la recette</h1>
              </div>
            </Grid>
            <Grid item>
              <h2 style={{ marginLeft: 10 }}>{allergens}</h2>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box style={{ marginBottom: '2%' }}>
        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
          <img src="/logo_bis.svg" alt="logo_bis" />
          <h1 style={{ marginLeft: 10 }}>Informations nutritionnelles de la recette</h1>
        </div>
        <NutritionalValues recipe={recipe} />


        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', marginTop: "3%" }}>
          <img src="/logo_bis.svg" alt="logo_bis" />
          <h1 style={{ marginLeft: 10 }}>Les ingrédients de la recette</h1>
        </div>
        <IngredientsList ingredients={recipe.Ingredients} />
      </Box>
    </div>
  );
};

export default SeeRecipe;