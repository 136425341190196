import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import Dashboard from './components/Dashboard/Dashboard';
import './App.css';
import MenuEntry from './components/MenuEntry/MenuEntry';
import Help from './components/Help/Help';
import MyRecipes from './components/MyRecipes/MyRecipes';
import AddRecipes from './components/AddRecipes/AddRecipes';
import EditRecipe from './components/EditRecipe/EditRecipe';
import SeeRecipe from './components/SeeRecipe/SeeRecipe';
import { DataProvider, DataContext } from './DataProvider'; // Import du DataContext
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import LoginPage from './components/LoginPage/LoginPage';
import EcoCalculateur from './components/EcoCalculateur/EcoCalculateur';
import AltRecipes from './components/AltRecipes/AltRecipes';
import RestaurantView from './components/RestaurantView/RestaurantView';
import EcoInfos from './components/EcoInfos/EcoInfos';
import GenerateNewRecipe from './components/GenerateNewRecipe/GenerateNewRecipe';
import Directory from './components/Directory/Directory';
import Ressources from './components/Ressources/Ressources';
import CategoryPage from './components/CategoryPage/categoryPage';
import DirectorySubventions from './components/DirectorySubventions/DirectorySubventions';
import CSRD from './components/CSRD/CSRD';
import FormPage from './components/AddRessources/FormPage';
import RecipesReport from './components/RecipesReport/RecipesReport';
import DashboardV2 from './components/DashboardV2/DashboardV2';
import { useData } from './DataProvider'; // Import du hook useData
import { MsalProvider } from '@azure/msal-react';
import WelcomeView from './components/WelcomeView/WelcomeView';

const AppContent = () => {
  let navigate = useNavigate();
  const { restaurant, newUser } = useData();
  useEffect(() => {
    // if(newUser){
    //   navigate('/welcome');
    // }
  }, [newUser, navigate]);

  return (
    <div className="app">
      <Sidebar />
      <div className="content">
        <Routes>
          <Route exact path="/" element={<DashboardV2 />} />
          <Route path="/menuentry" element={<MenuEntry />} />
          <Route path="/myrecipes" element={<MyRecipes />} />
          <Route path="/help" element={<Help />} />
          <Route path="/addrecipes" element={<AddRecipes />} />
          <Route path="/seerecipe" element={<SeeRecipe />} />
          <Route path="/restaurantview" element={<RestaurantView />} />
          <Route path="/ecocalculateur" element={<EcoCalculateur />} />
          <Route path="/editrecipe" element={<EditRecipe />} />
          <Route path="/ecoinfos" element={<EcoInfos />} />
          <Route path="/altdurables" element={<AltRecipes />} />
          <Route path="/generatenewrecipe" element={<GenerateNewRecipe />} />
          <Route path="/directory" element={<Directory />} />
          <Route path="/directorySubventions" element={<DirectorySubventions />} />
          <Route path="/ressources" element={<Ressources />} />
          <Route path="/addressources" element={<FormPage />} />
          <Route path="/csrd" element={<CSRD />} />
          <Route path="/recipesreport" element={<RecipesReport />} />
          <Route path="/category/:categoryId" element={<CategoryPage />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/welcome" element={<WelcomeView />} /> 
          <Route path="/dashboardv2" element={<DashboardV2 />} /> 

        </Routes>
      </div>
    </div>
  );
};

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <AuthenticatedTemplate>
        <DataProvider>
          <Router>
            <AppContent />
          </Router>
        </DataProvider>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <LoginPage colorA="#145C8B" colorB="#38A48E" />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};

export default App;
