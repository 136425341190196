import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Typography,
  Alert,
  CircularProgress,
  Collapse
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useData } from '../../DataProvider.js';

const FileUploadModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const {accessToken } = useData();

  const handleClose = () => {
    if (!uploading) {
      setIsOpen(false);
      setSuccess(false);
      setFile(null);
      setError('');
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (!selectedFile.name.match(/\.(xlsx|xls)$/)) {
        setError('Veuillez sélectionner un fichier Excel valide (.xlsx ou .xls)');
        return;
      }
      setFile(selectedFile);
      setError('');
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Veuillez sélectionner un fichier');
      return;
    }

    setUploading(true);
    setError('');

    try {
      const formData = new FormData();
      formData.append('menuFile', file);
      formData.append('file_name', file.name);
      formData.append('restaurant', 'ceasaclayr1');

      const response = await fetch('https://foodprint-api.azure-api.net/uploadmenu/http_trigger_uploadMenu', {
        method: 'POST',
        body: formData,
        headers: {
            'Ocp-Apim-Subscription-Key': 'a9ab279af7544e16a0ee106909e1074f',
            'Authorization': `Bearer ${accessToken}`,  

        },
      });

      if (!response.ok) {
        throw new Error('Erreur lors du téléversement');
      }

      setSuccess(true);
      // On ne ferme plus automatiquement la modale pour laisser le temps de lire le message
    } catch (err) {
      setError("Une erreur s'est produite lors du téléversement");
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      <Button 
        variant="contained" 
        onClick={() => setIsOpen(true)}
        startIcon={<CloudUploadIcon />}
      >
        Déposer votre menu pour générer l'affichage automatiquement
      </Button>

      <Dialog 
        open={isOpen} 
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
        Déposer votre menu pour générer l'affichage automatiquement
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ p: 2 }}>
            <Collapse in={!success}>
              <Box
                sx={{
                  border: '2px dashed #ccc',
                  borderRadius: 2,
                  p: 3,
                  textAlign: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  }
                }}
                component="label"
              >
                <input
                  type="file"
                  hidden
                  accept=".xlsx,.xls"
                  onChange={handleFileChange}
                />
                <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
                <Typography variant="body1" gutterBottom>
                  Cliquez pour déposer votre fichier ou glissez-déposez
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  .XLSX, .XLS
                </Typography>
              </Box>

              {file && (
                <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
                  Fichier sélectionné: {file.name}
                </Typography>
              )}

              {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              )}
            </Collapse>

            <Collapse in={success}>
              <Box sx={{ textAlign: 'center', py: 3 }}>
                <CheckCircleOutlineIcon color="success" sx={{ fontSize: 64, mb: 2 }} />
                <Typography variant="h6" gutterBottom color="success.main">
                  Fichier déposé avec succès !
                </Typography>
                <Alert severity="info" sx={{ mt: 2 }}>
                  Les affichages sont en cours de création à partir de votre fichier Excel. 
                </Alert>
              </Box>
            </Collapse>
          </Box>
        </DialogContent>

        <DialogActions sx={{ p: 2 }}>
          {!success ? (
            <>
              <Button
                variant="outlined"
                onClick={handleClose}
                disabled={uploading}
              >
                Annuler
              </Button>
              <Button
                variant="contained"
                onClick={handleUpload}
                disabled={!file || uploading}
                startIcon={uploading ? <CircularProgress size={20} /> : <CloudUploadIcon />}
              >
                {uploading ? 'Téléversement...' : 'Téléversement'}
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              onClick={handleClose}
              color="success"
            >
              Fermer
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FileUploadModal;